module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-react-i18next@0.0.25_gatsby@5.13.3_i18next@19.9.2_react-i18next@11.18.6_react@18.2.0/node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","cn","ja","de","es","fr","ko","it","nl","pl","vi","ru","pt","tr"],"defaultLanguage":"en","path":"/opt/buildhome/repo/locales","siteUrl":"app.ens.domains","i18nextOptions":{"debug":true,"lng":"en","fallbackLng":"en","whitelist":["en","cn","ja","de","es","fr","ko","it","nl","pl","vi","ru","pt","tr"],"interpolation":{"escapeValue":false}},"pages":[{"matchPath":"/ignored-page","languages":["en","cn","ja","de","es","fr","ko","it","nl","pl","vi","ru","pt","tr"]}]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-styled-components@6.13.1_babel-plugin-styled-components@2.1.4_gatsby@5.13.3_rea_cszwf6jegfsyluz2ilbklqe3wa/node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.3_babel-eslint@10.1.0_eslint-plugin-jest@27.9.0_react-dom@18.2.0_react@18.2.0_typescript@5.3.3/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
